import React from "react";
import WebcamCapture from "../WebcamCapture";
import BackImg from '../../assets/images/back-document.png';

const BackDocument = ({ handleOnClickNext, dispatch}) => {
  return (
    <div className="w-full flex flex-col align-center justify-evenly flex-grow fade-in">
      <h2 className="block text-gray-700 text-sm font-bold mb-2 mt-4 text-base md:text-lg xl:text-xl lg:mt-8 lg:mb-4">
        Sube una imagen del lado posterior
      </h2>
      <img src={BackImg} alt="BackDoc" className="object-contain w-1/3 h-1/3 xl:w-1/3 xl:h-1/3 self-center" />
      <WebcamCapture content="Subir archivo" handleOnClickNext={handleOnClickNext} setFrontImg={false} dispatch={dispatch} />
    </div>
  );
};

export default BackDocument;
