import React, { useState, Fragment } from "react";
import ReactFlagsSelect from "react-flags-select";
import { customLabels } from "../../data/countries";

const CountrySelector = (props) => {
    const [selected, setSelected] = useState("");

    return (
        <Fragment>
            <div className="w-full flex flex-col align-center justify-evenly flex-grow fade-in">
                <h1 className="block text-gray-700 text-base font-bold mb-2 -mt-4 text-base md:text-lg xl:text-xl lg:-mt-8 lg:mb-4">
                    Selecciona el país
                </h1>
                <div className="flex flex-col justify-center">
                    <p className="m-5">Elige el país al que pertenece el documento que validarás.</p>
                    <ReactFlagsSelect
                        selected={selected}
                        searchable={true}
                        placeholder="Seleccionar país..."
                        customLabels={customLabels}
                        onSelect={(code) => setSelected(code)}
                    />
                </div>

                <input
                    className={selected !== "" ? "fna-color w-40 self-center py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline cursor-pointer" : "disabled disabled:bg-slate-700 disabled:text-white w-40 self-center py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline"}
                    type="button"
                    disabled={selected !== "" ? false : true}
                    value={selected !== "" ? "Siguiente" : "Selecciona un país"}
                    onClick={() => {
                        props.dispatch({
                            type: "country",
                            value: selected,
                        });
                        props.handleOnClickNext();
                    }}
                />
            </div>
        </Fragment>
    );
};

export default CountrySelector;