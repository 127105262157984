import React, { Fragment, useState, useReducer, useEffect } from "react";
import Stepper from "./Stepper";
import InitialInformation from "./steps/InitialInformation";
import FrontDocument from "./steps/FrontDocument";
import BackDocument from "./steps/BackDocument";
import SelfieVideo from "./steps/SelfieVideo";
import SelfieVideoDesktop from "./steps/SelfieVideoDesktop";
import CloseSVG from "../assets/images/close.svg";
import FinalStep from "./steps/FinalStep";
import Logo from "../assets/images/Logo.png";
import {
  sendMessage,
  getDeviceType,
} from "./../Utils";
import CountrySelector from "./steps/CountrySelector";

const complexStateInitial = {
  frontDocument: null,
  backDocument: null,
  selfieVideo: null,
  documentType: "national-id",
  country: null,
  // .. more states
};

const closeStylesError = {
  maxWidth: "25px",
  position: "absolute",
  alignSelf: "flex-start",
};

function reducer(state, action) {
  switch (action.type) {
    case "frontDocument":
      return { ...state, frontDocument: action.value };
    case "backDocument":
      return { ...state, backDocument: action.value };
    case "selfieVideo":
      return { ...state, selfieVideo: action.value };
    case "documentType":
      return { ...state, documentType: action.value };
    case "country":
      return { ...state, country: action.value };

    // ... more states
    default:
      throw new Error();
  }
}

const StepperForm = (props) => {
  const [state, dispatch] = useReducer(reducer, complexStateInitial);
  const [activeStep, setActiveStep] = useState(1);
  const [frontImageDoc, setFrontImageDoc] = useState([]);
  const [deviceType, setDeviceType] = useState();
  const [openFromIframe, setOpenFromIframe] = useState();
  const steps = [
    { title: "Introducción" },
    { title: "País" },
    { title: "Documento frontal" },
    { title: "Documento posterior" },
    { title: "Vídeo del rostro" },
    { title: "Finalizar" },
  ];
  const videoIndex = steps
    .map(function (e) {
      return e.title;
    })
    .indexOf("Video Selfie");

  const backDocIndex = steps
    .map(function (e) {
      return e.title;
    })
    .indexOf("Documento posterior");

  if (
    (props.canVideo === false || props.canVideo === undefined) &&
    videoIndex > -1
  ) {
    steps.splice(videoIndex, 1);
  }

  if(props.country) {
    let countryIndex = steps
      .map(function (e) {
        return e.title;
      })
      .indexOf("País");
    steps.splice(countryIndex, 1);
  }

  if (state.documentType === "passport" && backDocIndex > -1) {
    steps.splice(backDocIndex, 1);
    console.log(steps);
  }

  // const handleOnClickStepper = (step) => {
  //   setActiveStep(step);
  // };

  const handleOnClickNext = () => {
    let nextStep = activeStep + 1;
    setActiveStep(nextStep);
  };

  useEffect(() => {
    const deviceType = getDeviceType();
    window === window.parent
      ? setOpenFromIframe(false)
      : setOpenFromIframe(true);
    setDeviceType(deviceType);
  }, []);

  const closePopUp = (e) => {
    e.preventDefault();

    sendMessage("exitedSdk", { data: "exited manually by user" });
  };

  const onChangeUseReducer = (e) => {
    const { name, value } = e.target;
    dispatch({ type: name, value });
  };

  useEffect(() => {
    const deviceType = getDeviceType();
    window === window.parent
      ? setOpenFromIframe(false)
      : setOpenFromIframe(true);
    setDeviceType(deviceType);
  }, []);

  return (
    <Fragment>
      <div className="flex items-center justify-center w-full md:w-3/5 lg:w-1/2 p-2 md:p-6 h-full max-h-full">
        <div className="min-w-full w-full h-full lg:w-1/2 relative">
          {openFromIframe ? (
            <button onClick={closePopUp} className="close-frame absolute top-0 right-0 m-5 cursor-pointer">
              {" "}
              <img alt="Close" src={CloseSVG}></img>
            </button>
          ) : null}
          <div className="bg-white shadow-md px-8 pt-6 pb-8 mb-4 rounded-lg shadow-xl h-full flex flex-col items-center justify-center" style={{ minHeight: "35rem" }}>
            {!props.error ? (
                <div className="w-full h-full flex flex-col items-center justify-center flex-col justify-between py-4 md:px-6 xl:px-20 lg:py-2" style={{ minHeight: "35rem" }}>
                  <img
                    src={Logo}
                    className="object-contain md:w-1/3 w-48 self-center"
                    alt="Logo Quota Rent"
                  />

                  {activeStep ===
                    steps.map((e) => e.title).indexOf("Introducción") + 1 && (
                    <InitialInformation
                      currentStep={activeStep}
                      handleChange={onChangeUseReducer}
                      documentType={state.documentType}
                      dispatch={dispatch}
                      steps={steps}
                      handleOnClickNext={handleOnClickNext}
                    />
                  )}

                  {activeStep ===
                    steps.map((e) => e.title).indexOf("País") + 1 && (
                    <CountrySelector
                      setFrontImageDoc={setFrontImageDoc}
                      currentStep={activeStep}
                      handleChange={onChangeUseReducer}
                      frontDocument={state.frontDocument}
                      steps={steps}
                      dispatch={dispatch}
                      handleOnClickNext={handleOnClickNext}
                    />
                  )}

                  {activeStep ===
                    steps.map((e) => e.title).indexOf("Documento frontal") + 1 && (
                    <FrontDocument
                      setFrontImageDoc={setFrontImageDoc}
                      currentStep={activeStep}
                      handleChange={onChangeUseReducer}
                      frontDocument={state.frontDocument}
                      steps={steps}
                      dispatch={dispatch}
                      handleOnClickNext={handleOnClickNext}
                    />
                  )}

                  {activeStep ===
                    steps.map((e) => e.title).indexOf("Documento posterior") + 1 && (
                    <BackDocument
                      currentStep={activeStep}
                      handleChange={onChangeUseReducer}
                      backDocument={state.backDocument}
                      steps={steps}
                      dispatch={dispatch}
                      handleOnClickNext={handleOnClickNext}
                    />
                  )}

                  {activeStep ===
                    steps.map((e) => e.title).indexOf("Vídeo del rostro") + 1 && (
                    deviceType === "desktop" ? (
                      <SelfieVideoDesktop
                        currentStep={activeStep}
                        handleChange={onChangeUseReducer}
                        selfieVideo={state.selfieVideo}
                        steps={steps}
                        dispatch={dispatch}
                        handleOnClickNext={handleOnClickNext}
                      />
                    ) : (
                      <SelfieVideo
                        currentStep={activeStep}
                        handleChange={onChangeUseReducer}
                        selfieVideo={state.selfieVideo}
                        steps={steps}
                        dispatch={dispatch}
                        handleOnClickNext={handleOnClickNext}
                      />
                    )
                  )}

                  {activeStep ===
                    steps.map((e) => e.title).indexOf("Finalizar") + 1 && (
                    <FinalStep
                      currentStep={activeStep}
                      steps={steps}
                      state={state}
                      // handleSubmitAll={handleSubmitAll}
                      frontImageDoc={frontImageDoc}
                      userId={props.userId}
                      contractId={props.contractId}
                      token={props.token}
                      canVideo={props.canVideo}
                      phoneNumberEnc={props.phoneNumberEnc}
                      country={props.country}
                    />
                  )}

                  <Stepper
                    steps={steps}
                    activeStep={activeStep}
                    // onSelect={num => { handleOnClickStepper(num) }}
                    showNumber={false}
                  />

                </div>
            ) : (
              <Fragment>
                {openFromIframe ? (
                  <button onClick={closePopUp} style={closeStylesError}>
                    {" "}
                    <img alt="Close" src={CloseSVG}></img>
                  </button>
                ) : null}
                <div>
                  <p>Validación expirada.</p>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default StepperForm;
